import React from "react";
import AppRoutes from './components/appRoutes';



function App() {
  return (
        <div>
       <AppRoutes />
       </div>
  );
}

export default App;
